import { dispatcher } from 'react-structure-admin';

export const JOB = 'JOB';

export const JOB_OPEN = 'JOB_OPEN';
export const JOB_CLOSE = 'JOB_CLOSE';

export const openJobs = () => async (dispatch) => {
  dispatch(dispatcher.createAction(JOB_OPEN, 'jobs'));
};

export const closeJobs = () => (dispatch) => {
  dispatch(dispatcher.createAction(JOB_CLOSE, 'jobs'));
};
