import {
  updateResourceState,
  createResourceReducer,
  RESOURCE
} from 'react-structure-admin';
import { JOB, JOB_CLOSE, JOB_OPEN } from './jobActions';

const jobReducer = createResourceReducer(
  JOB,
  [],
  {
    [JOB_OPEN](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: JOB_OPEN
      }));
    },

    [JOB_CLOSE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    }
  },

  (state, action) => {
    return updateResourceState(state, action, RESOURCE, () => ({}));
  }
);

export default jobReducer;
