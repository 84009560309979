import React from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Tag } from 'antd';
import { Crud } from 'react-structure-admin';
import { closeJobs, JOB_OPEN } from 'stores/jobs/jobActions';
import { formartDateTimeUTC } from 'util/formatters';
import { jobStatus } from 'pages/app/jobs/jobListStatus';
import { ReduxRender } from 'core';

const JobList = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeJobs());
  };

  return (
    <Drawer
      visible
      title="Tarefas"
      className="gx-drawer-form md"
      onClose={handleClose}
    >
      <Crud
        resource="jobs"
        useQueryStringParams={false}
        headerComponent={() => null}
        showActions={false}
        showNewButton={false}
        columns={[
          {
            title: 'Data',
            dataIndex: 'creationTime',
            render: (creationTime) => formartDateTimeUTC(creationTime),
            width: '20%'
          },
          {
            title: 'Tarefa',
            dataIndex: 'resource',
            width: '40%'
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => (
              <Tag color={jobStatus[status]?.color}>
                {jobStatus[status]?.display}
              </Tag>
            ),
            width: '20%'
          }
        ]}
      />
    </Drawer>
  );
};

export default ReduxRender.create(JobList, {
  condition: (state) => state.resources?.jobs?.activeModal == JOB_OPEN,
  mapProps: () => ({})
});
