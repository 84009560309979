import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { settingsReducer, userReducer } from 'stores';
import { resourceReducer, concatReducers } from 'react-structure-admin';
import companyReducer from './companies/companyReducer';
import authReducer from './auth/authReducer';
import platformReducer from './platforms/platformReducer';
import productReducer from './products/productReducer';
import subscriptionReducer from './subscriptions/subscriptionReducer';
import orderReducer from './orders/orderReducer';
import monthlyClosingReducer from './monthly-closings/monthlyClosingsReducer';
import dashboardReducer from './dashboards/dashboardReducer';
import onboardingReducer from './onboarding/onboardingReducer';
import accountReducer from './accounts/accountReducer';
import referralProgramReducer from './referralPrograms/referralProgramReducer';
import announcementReducer from './announcements/announcementReducer';
import cityReducer from './cities/cityReducer';
import invoiceReducer from './invoices/invoiceReducer';
import notificationReducer from './notifications/notificationReducer';
import jobReducer from './jobs/jobReducer';

const resourceRedurcers = concatReducers(
  [
    resourceReducer,
    userReducer,
    companyReducer,
    platformReducer,
    subscriptionReducer,
    orderReducer,
    monthlyClosingReducer,
    dashboardReducer,
    productReducer,
    accountReducer,
    referralProgramReducer,
    announcementReducer,
    cityReducer,
    invoiceReducer,
    notificationReducer,
    jobReducer
  ],
  []
);

export default (history) => {
  const reducerMap = {
    router: connectRouter(history),
    settings: settingsReducer,
    auth: authReducer,
    onboarding: onboardingReducer,
    resources: resourceRedurcers
  };

  return combineReducers(reducerMap);
};
