import React, { useState, useEffect } from 'react';
import {
  Row,
  Form,
  Divider,
  Button,
  Drawer,
  Input,
  Checkbox,
  Switch
} from 'antd';
import {
  api,
  FormItemWrap,
  configManager,
  useCrudEditContext,
  generateUUID,
  useAuthContext,
  SearchValue
} from 'react-structure-admin';
import { InputPercent, ResCol } from 'components';
import { locationTypesDisplay } from 'constants/types';
import SelectTaxationType from 'components/SelectTaxationType';
import SelectProvisionMode from 'components/SelectProvisionMode';
import SelectLinkBetweenPart from 'components/SelectLinkBetweenPart';
import SelectLinkOperation from 'components/SelectLinkOperation';
import SelectProvisionSupportMechanism from 'components/SelectProvisionSupportMechanism';
import SelectBorrowerSupportMechanism from 'components/SelectBorrowerSupportMechanism';

const supportedLocationTypes = [
  'companyMunicipality',
  'outsideCompanyMunicipality',
  'foreign'
];

const TaxationGroupServiceInvoiceRuleEdit = ({ editingRule, onClose }) => {
  const [form] = Form.useForm();
  const { company } = useAuthContext();
  const { form: mainForm } = useCrudEditContext();

  const [issueType, setIssueType] = useState(editingRule.issueType);
  const [providerSettings, setProviderSettings] = useState({});
  const [locationType, setLocationType] = useState(
    editingRule.locationTypes ?? supportedLocationTypes
  );

  let { numberDecimalPlaces } = providerSettings;

  const isEditing =
    editingRule?.id !== undefined || editingRule?.uuid !== undefined;

  const handleInsertOrUpdate = (values) => {
    if (!isEditing) {
      values.uuid = generateUUID();
    }

    const { rules = [] } = mainForm.getFieldsValue();
    const key = editingRule?.id ?? editingRule?.uuid;

    const index = rules.findIndex(
      (c) =>
        (c?.id !== undefined && c.id === key) ||
        (c?.uuid !== undefined && c.uuid) === key
    );

    if (index > -1) {
      rules[index] = values;
    } else {
      rules.push(values);
    }

    mainForm.setFieldsValue({ rules: [...rules] });
    form.resetFields();
    onClose();
  };

  const handleBeforeSave = ({ ...rest }) => {
    return {
      ...rest
    };
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      values = handleBeforeSave({ ...editingRule, ...values });
      handleInsertOrUpdate(values);
    });
  };

  const handleBeforeBinding = ({ ...rest }) => {
    return {
      ...rest
    };
  };

  useEffect(() => {
    api.fetch(`companies/${company.id}/settings`).then(({ data }) => {
      const { settings, providerSettings } = data.result;

      setProviderSettings(providerSettings);
      setIssueType(settings.serviceInvoice.issueType);
    });
  }, []);

  const initialValues = handleBeforeBinding(editingRule);

  const locationTypes = supportedLocationTypes.map((type) => ({
    label: locationTypesDisplay[type],
    value: type
  }));

  const handleChangeLocationType = (value) => {
    setLocationType(value);
  };

  return (
    <Drawer
      visible
      width={numberDecimalPlaces > 2 ? 750 : 680}
      title="Regra NFS-e"
      bodyStyle={{ paddingBottom: 80 }}
      onClose={onClose}
      footer={
        <div
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }}
        >
          <Button onClick={onClose}>Voltar</Button>
          <Button type="primary" onClick={handleSubmit}>
            Salvar
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        {...configManager.getConfig().layout.form}
        initialValues={initialValues}
      >
        <Row>
          <ResCol span={24}>
            <Divider orientation="left">Localidade do cliente</Divider>
            <FormItemWrap
              name="locationTypes"
              initialValue={supportedLocationTypes}
              rules={[
                {
                  required: true,
                  message: 'Localidade do cliente é obrigatório.'
                }
              ]}
            >
              <Checkbox.Group
                options={locationTypes}
                onChange={handleChangeLocationType}
              />
            </FormItemWrap>
          </ResCol>
        </Row>

        <Row>
          <FormItemWrap name={['producer', 'id']} hidden>
            <Input />
          </FormItemWrap>
          <ResCol span={24}>
            <FormItemWrap label="Natureza da Operação" name={['taxationType']}>
              <SelectTaxationType />
            </FormItemWrap>
          </ResCol>
        </Row>

        <Row>
          <ResCol span={6}>
            <FormItemWrap label="ISS" name={['issRate']}>
              <InputPercent decimalPlaces={numberDecimalPlaces} />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap label="PIS" name={['pisRate']}>
              <InputPercent decimalPlaces={numberDecimalPlaces} />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap label="COFINS" name={['cofinsRate']}>
              <InputPercent decimalPlaces={numberDecimalPlaces} />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap label="IR" name={['irRate']}>
              <InputPercent decimalPlaces={numberDecimalPlaces} />
            </FormItemWrap>
          </ResCol>
        </Row>
        <Row>
          <ResCol span={6}>
            <FormItemWrap
              name={['issWithheld']}
              label="Reter ISS?"
              valuePropName="checked"
            >
              <Switch />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap
              name={['pisWithheld']}
              label="Reter PIS?"
              valuePropName="checked"
            >
              <Switch />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap
              name={['cofinsWithheld']}
              label="Reter COFINS?"
              valuePropName="checked"
            >
              <Switch />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap
              name={['irWithheld']}
              label="Reter IR?"
              valuePropName="checked"
            >
              <Switch />
            </FormItemWrap>
          </ResCol>
        </Row>
        <Row>
          <ResCol span={6}>
            <FormItemWrap label="INSS" name={['inssRate']}>
              <InputPercent decimalPlaces={numberDecimalPlaces} />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap label="CSLL" name={['csllRate']}>
              <InputPercent decimalPlaces={numberDecimalPlaces} />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap label="Deduções" name={['deductionsRate']}>
              <InputPercent decimalPlaces={numberDecimalPlaces} />
            </FormItemWrap>
          </ResCol>
        </Row>
        <Row>
          <ResCol span={6}>
            <FormItemWrap
              name={['inssWithheld']}
              labelAlign="center"
              label="Reter INSS?"
              valuePropName="checked"
            >
              <Switch />
            </FormItemWrap>
          </ResCol>
          <ResCol span={6}>
            <FormItemWrap
              name={['csllWithheld']}
              label="Reter CSLL?"
              valuePropName="checked"
            >
              <Switch />
            </FormItemWrap>
          </ResCol>
        </Row>
        <Row>
          <ResCol span={24}>
            <FormItemWrap
              label="Informações adicionais"
              name={['additionalInformation']}
              whitespace
            >
              <Input.TextArea maxLength={2000} rows={3} />
            </FormItemWrap>
          </ResCol>
        </Row>

        {locationType?.includes('foreign') && issueType === 'annfs' ? (
          <>
            <Divider orientation="left">
              Informações de Comércio Exterior
            </Divider>
            <Row>
              <ResCol span={12}>
                <FormItemWrap
                  label="Modo de prestação"
                  name={['serviceProvisionMode']}
                >
                  <SelectProvisionMode />
                </FormItemWrap>
              </ResCol>
              <ResCol span={12}>
                <FormItemWrap
                  label="Vínculo entre as partes"
                  name={['relationshipBetweenParties']}
                >
                  <SelectLinkBetweenPart />
                </FormItemWrap>
              </ResCol>
            </Row>
            <Row>
              <ResCol span={12}>
                <FormItemWrap
                  label="Fomento utilizado pelo prestador do serviço"
                  name={['supportMechanismForServiceProvider']}
                >
                  <SelectProvisionSupportMechanism />
                </FormItemWrap>
              </ResCol>
              <ResCol span={12}>
                <FormItemWrap
                  label="Fomento utilizado pelo tomador do serviço"
                  name={['supportMechanismForBorrower']}
                >
                  <SelectBorrowerSupportMechanism />
                </FormItemWrap>
              </ResCol>
            </Row>
            <Row>
              <ResCol span={16}>
                <FormItemWrap
                  label="Vínculo da Operação à Movimentação Temporária de Bens"
                  name={['relationshipBetweenOperationAndTemporaryMovement']}
                >
                  <SelectLinkOperation />
                </FormItemWrap>
              </ResCol>
              <ResCol span={8}>
                <FormItemWrap
                  name={['sendInvoiceToMdic']}
                  label="Enviar NFS-e ao MDIC?"
                  valuePropName="checked"
                >
                  <Switch />
                </FormItemWrap>
              </ResCol>
            </Row>
          </>
        ) : null}
      </Form>
    </Drawer>
  );
};

export default TaxationGroupServiceInvoiceRuleEdit;
